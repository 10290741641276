import React from 'react';
// import Slider from 'react-slick'; // Import React Slick component
import 'slick-carousel/slick/slick.css'; // Slick core CSS
import 'slick-carousel/slick/slick-theme.css'; // Slick theme CSS
import moviesN from '../../image/Movies.png';
import ECommerceShopN from '../../image/Ecomerce.png';
import ekommerceNextjsN from '../../image/Cars.png';
import treningN from '../../image/Trening.png';
import gpbmN from '../../image/GPBM.png';
import restaurangN from '../../image/Restourang.png';
import RecepiAppN from '../../images/RecepiAppN.png';
import todoN from '../../image/Todo.png';
import weatherReact from '../../image/Weather.png';
import PortfolioJSN from '../../image/Portfolio.png';
// import MovieOnlineN from '../../images/MovieOnlineN.png';
import BeautifN from '../../image/Beautiful.png';
import BuildingCommpanyN from '../../image/Bukovac.png';
import weatherN from '../../image/Weather.png';
import todoMySQLN from '../../image/Todo.png';
import Kodex from '../../image/kodex.png';
import './projects.css'; // Your custom CSS

const Projects = () => {
  const projectImages = [
    { link: 'https://popcorn-app.vercel.app/', image: moviesN },
    { link: 'https://e-commerce-9b61e5.gitlab.io/', image: ECommerceShopN },
    {
      link: 'https://cars-ekommerce-nextjs.vercel.app/',
      image: ekommerceNextjsN,
    },
    { link: 'https://trenning-app.vercel.app/', image: treningN },
    { link: 'https://battery-front.onrender.com/', image: gpbmN },
    { link: 'https://tastydelights.netlify.app/', image: restaurangN },
    {
      link: 'https://recipes-app-u08.netlify.app',
      image: RecepiAppN,
    },
    { link: 'https://rococo-starship-2faac9.netlify.app/', image: todoN },
    { link: 'https://app-for-weather-react.netlify.app/', image: weatherReact },
    { link: 'https://www.nikolovski.se/#', image: PortfolioJSN },
    // { image: MovieOnlineN },
    { link: 'https://beautif.ai/', image: BeautifN },
    { link: 'https://www.uniquedesign.se/', image: BuildingCommpanyN },
    { link: '', image: weatherN },
    { link: '', image: todoMySQLN },
    {
      link: 'https://kodex-vande-nikolovski-06eeb63ebe54e5513523da176297594872e3f15a.gitlab.io/#login',
      image: Kodex,
    },
  ];

  return (
    <section className="projects" id="projects">
      <div className="projects-container">
        <h2 className="projects-head">
          My <span>Projects</span>
        </h2>
        <div className="text-animate">
          <h3>My last projects</h3>
        </div>
      </div>
      <div class="grid-item" id="projects">
        <div className="grid-item" id="projects">
          <div className="carousel-container">
            <section id="section">
              {' '}
              {/* Keep the section wrapper for styling */}
              <ul className="carousel-content">
                {projectImages.map((project, index) => (
                  <li key={index} className="carousel-item">
                    {project.link ? (
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={project.image}
                          alt={`Proyecto ${index + 1}`}
                        />
                      </a>
                    ) : (
                      <img src={project.image} alt={`Proyecto ${index + 1}`} />
                    )}
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
